import * as React from 'react';
import Layout from 'ui/layouts';
import { Seo } from 'components/seo';

const NotFoundPage: React.FC = () => (
  <Layout>
    <Seo title="404 page not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
